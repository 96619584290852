/* RESPONSIBLE TEAM: team-ai-agent-2 */

export interface ActionPlanWireFormat {
  action_id: string;
  action_name: string;
  otp_auth_enabled: boolean;
}

export class ActionStep {
  constructor(
    public id: string,
    public name: string,
    public otpAuthEnabled: boolean,
  ) {
    this.id = id;
    this.name = name;
    this.otpAuthEnabled = otpAuthEnabled;
  }

  static deserialize(data: ActionPlanWireFormat): ActionStep {
    return new ActionStep(data.action_id, data.action_name, data.otp_auth_enabled);
  }
}

export class ActionPlan {
  constructor(public steps: ActionStep[]) {
    this.steps = steps;
  }

  static deserialize(data: ActionPlanWireFormat[] = []): ActionPlan {
    return new ActionPlan(data.map((step) => ActionStep.deserialize(step)));
  }

  get hasSteps() {
    return this.steps.length > 0;
  }

  get hasMultipleSteps() {
    return this.steps.length > 1;
  }

  get requiresOtpAuth() {
    return this.steps.some((step) => step.otpAuthEnabled);
  }
}
