/* RESPONSIBLE TEAM: team-ai-agent-2 */

import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';
import { type BlockList } from '@intercom/interblocks.ts';
import { tracked } from '@glimmer/tracking';
import { type AnswerLength, type Tone } from 'embercom/components/operator/fin/setup/personality';
import { guidelineWithoutTemplateVars } from 'embercom/helpers/ai-agent-2/playground-helper';
import { EntityType } from 'embercom/models/data/entity-types';
import { ActionPlan, type ActionPlanWireFormat } from './action-plan';

export enum AnswerState {
  Answered = 'answered',
  Unanswered = 'unanswered',
}

export const ANSWER_STATE_ANSWER_TYPE_MAP = {
  [AnswerState.Answered]: ['inline_answer', 'inline_answer_with_disambiguation', 'phatic_reply'],
  [AnswerState.Unanswered]: ['clarification', 'bot_reply', 'out_of_domain'],
};

export enum CustomerAnswerRating {
  Positive = 'positive',
  Negative = 'negative',
}

export enum Status {
  Pending = 'pending',
  Running = 'running',
  Completed = 'completed',
  Failed = 'failed',
}

export enum CustomerRatingReason {
  DidNotUseRightContentSources = 'did_not_use_right_content_sources',
  DidNotClarifyCustomerQuestion = 'did_not_clarify_customer_question',
  UsedContentIncorrectly = 'used_content_incorrectly',
  ToneWasNotRight = 'tone_was_not_right',
  AnswerTooLong = 'answer_too_long',
  DidNotSpeakInRightLanguage = 'did_not_speak_in_right_language',
  Other = 'other',
}

export interface AppliedGuideline {
  id: string;
  text: string;
  title: string;
}

export interface TriggeredWorkflow {
  id: string;
  title: string;
  description: string;
  rulesetId: string;
}

type ExcludeFunctionPropertyNames<T> = Pick<
  T,
  {
    [K in keyof T]: T[K] extends Function ? never : K;
  }[keyof T]
>;

export type PlaygroundQuestionWireFormat = Omit<
  KeysToSnakeCase<ExcludeFunctionPropertyNames<PlaygroundQuestion>>,
  | 'sources'
  | 'action_plan'
  // Getters cannot be excluded based on the type, so we need to exclude them manually
  | 'has_sources'
  | 'has_no_answer'
  | 'has_answer'
  | 'has_error'
  | 'answer_has_error'
  | 'guidelines_without_template_vars'
  | 'severity'
  | 'is_bulk_selected'
> & {
  action_plan: ActionPlanWireFormat[];
  sources: KeysToSnakeCase<PlaygroundQuestionSource>[];
  fin_playground_group_id: string;
  triggered_workflow?: KeysToSnakeCase<TriggeredWorkflow>;
};

export class PlaygroundQuestionSource {
  constructor(
    public entityId: number,
    public entityType: number,
    public entityData: {
      title: string;
    },
    public deleted: boolean,
  ) {}

  get title() {
    return this.entityData.title;
  }

  get humanizedEntityTypeName() {
    return humanReadableObjectNames[this.entityType];
  }
}

export class PlaygroundQuestion {
  @tracked questionText: string;
  @tracked status: Status;
  @tracked isBulkSelected = false;
  customerAnswerRatingNote: string;

  id: number | undefined;
  responseText: BlockList = [];
  actionPlan: ActionPlan;
  sources: PlaygroundQuestionSource[] = [];
  customerAnswerRating?: CustomerAnswerRating;
  customerAnswerRatingReason?: CustomerRatingReason;
  answerType?:
    | 'inline_answer'
    | 'clarification'
    | 'bot_reply'
    | 'inline_answer_with_disambiguation'
    | 'workflow_handover';
  answerLastGeneratedAt: Date | undefined;
  aiToneOfVoice?: Tone;
  aiAnswerLength?: AnswerLength;
  appliedGuidelines: AppliedGuideline[];
  triggeredWorkflow?: TriggeredWorkflow;
  fallbackSearchLocale: string | undefined;
  realtimeTranslationApplied: boolean;
  groupId?: number;
  questionDetectedLocale?: string;
  detectedLocale?: string;

  constructor(
    id: number | undefined,
    questionText: string,
    aiToneOfVoice?: Tone,
    aiAnswerLength?: AnswerLength,
    appliedGuidelines?: AppliedGuideline[],
    triggeredWorkflow?: TriggeredWorkflow,
    responseText?: BlockList,
    actionPlan?: ActionPlan,
    sources?: PlaygroundQuestionSource[],
    status?: Status,
    answerType?: PlaygroundQuestion['answerType'],
    customerAnswerRating?: CustomerAnswerRating,
    answerLastGeneratedAt?: Date,
    customerAnswerRatingReason?: CustomerRatingReason,
    customerAnswerRatingNote?: string,
    fallbackSearchLocale?: string,
    realtimeTranslationApplied?: boolean,
    groupId?: number,
    questionDetectedLocale?: string,
    detectedLocale?: string,
  ) {
    this.id = id;
    this.questionText = questionText;
    this.status = status ?? Status.Pending;
    this.responseText = responseText ?? [];
    this.actionPlan = actionPlan ?? new ActionPlan([]);
    this.sources = this.sortSourcesByFinActionsFirst(sources ?? []);
    this.answerType = answerType;
    this.customerAnswerRating = customerAnswerRating;
    this.answerLastGeneratedAt = answerLastGeneratedAt;
    this.customerAnswerRatingReason = customerAnswerRatingReason;
    this.aiToneOfVoice = aiToneOfVoice;
    this.aiAnswerLength = aiAnswerLength;
    this.appliedGuidelines = appliedGuidelines ?? [];
    this.triggeredWorkflow = triggeredWorkflow;
    this.customerAnswerRatingNote = customerAnswerRatingNote ?? '';
    this.fallbackSearchLocale = fallbackSearchLocale;
    this.realtimeTranslationApplied = realtimeTranslationApplied ?? false;
    this.groupId = groupId;
    this.questionDetectedLocale = questionDetectedLocale;
    this.detectedLocale = detectedLocale;
  }

  static deserialize(data: PlaygroundQuestionWireFormat): PlaygroundQuestion {
    let answerGeneratedAt: Date | undefined;
    let triggeredWorkflow: TriggeredWorkflow | undefined;

    if (data.answer_last_generated_at) {
      answerGeneratedAt = new Date(data.answer_last_generated_at);
    }

    if (data.triggered_workflow) {
      triggeredWorkflow = {
        id: data.triggered_workflow.id,
        title: data.triggered_workflow.title,
        description: data.triggered_workflow.description,
        rulesetId: data.triggered_workflow.ruleset_id,
      };
    }

    return new PlaygroundQuestion(
      Number(data.id),
      data.question_text,
      data.ai_tone_of_voice,
      data.ai_answer_length,
      data.applied_guidelines,
      triggeredWorkflow,
      data.response_text,
      ActionPlan.deserialize(data.action_plan),
      data.sources?.map(
        (s) => new PlaygroundQuestionSource(s.entity_id, s.entity_type, s.entity_data, s.deleted),
      ),
      data.status,
      data.answer_type,
      data.customer_answer_rating,
      answerGeneratedAt,
      data.customer_answer_rating_reason,
      data.customer_answer_rating_note,
      data.fallback_search_locale,
      data.realtime_translation_applied,
      Number(data.fin_playground_group_id),
      data.question_detected_locale,
      data.detected_locale,
    );
  }

  get hasSources() {
    return this.sources?.length > 0;
  }

  get hasNoAnswer() {
    return (
      !this.answerType ||
      ANSWER_STATE_ANSWER_TYPE_MAP[AnswerState.Unanswered].includes(this.answerType)
    );
  }

  get answerHasError() {
    return this.status === Status.Failed;
  }

  run() {
    this.status = Status.Running;
  }

  guidelinesWithoutTemplateVars(): AppliedGuideline[] {
    return this.appliedGuidelines.map((guideline) => {
      // wrap the whole thing in a try/catch just in case there's an edge
      // case I hadn't considered.
      try {
        return {
          ...guideline,
          text: guidelineWithoutTemplateVars(guideline.text),
          title: guideline.title,
        };
      } catch (error) {
        return guideline;
      }
    });
  }

  private sortSourcesByFinActionsFirst(sources: PlaygroundQuestionSource[]) {
    return sources.sort((a, b) => {
      if (a.entityType === EntityType.WorkflowConnectorAction) {
        return -1;
      }
      if (b.entityType === EntityType.WorkflowConnectorAction) {
        return 1;
      }
      return 0;
    });
  }
}
