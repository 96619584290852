/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import {
  PRICING_5_X_CORE_EXPERT_ID,
  PRICING_5_X_CORE_ADVANCED_ID,
  PRICING_5_X_CORE_ESSENTIAL_ID,
  PROACTIVE_SUPPORT_PLUS_BASE_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type Breakdown from 'embercom/models/billing/price/breakdown';
import type Price from 'embercom/models/price';
import type IntlService from 'embercom/services/intl';
import moment from 'moment-timezone';
import type Charge from 'embercom/models/billing/price/charge';

export function getEstimatedLiteSeatCount(
  planId: string,
  adminsLength: number,
  corePlanBreakdown: Breakdown,
) {
  return planId === PRICING_5_X_CORE_ESSENTIAL_ID
    ? 0
    : adminsLength - getEstimatedCoreSeatCharge(corePlanBreakdown)!.actualUsage;
}

export function getEstimatedCoreSeatCharge(corePlanBreakdown: Breakdown | undefined) {
  return corePlanBreakdown?.charges.find(
    (charge) => charge.pricing_metric === Metric.core_seat_count,
  );
}

export function getCorePlanBreakdown(breakdowns: Breakdown[]) {
  return breakdowns.find(
    (breakdown) =>
      breakdown.plan_id &&
      [
        PRICING_5_X_CORE_ESSENTIAL_ID,
        PRICING_5_X_CORE_ADVANCED_ID,
        PRICING_5_X_CORE_EXPERT_ID,
      ].includes(breakdown.plan_id.toString()),
  );
}

export function getHasPSPUsage(priceWithPsp: Price) {
  let charge = getPspPlanBreakdown(priceWithPsp)?.charges.find(
    (charge: { pricing_metric: string }) => charge.pricing_metric === Metric.messages_sent,
  );

  return charge?.actual_usage > 0;
}

export function getCopilotBreakdown(price: Price) {
  return price?.breakdown?.find(
    (bd: Breakdown) => bd.plan_id && bd.plan_id.toString() === FIN_AI_COPILOT_BASE_ID,
  );
}

function getCopilotCharge(price: Price) {
  return getCopilotBreakdown(price)?.charges?.find(
    (charge: Charge) => charge.pricing_metric === Metric.copilot_seat_count,
  );
}

export function getCopilotUsage(price: Price) {
  return getCopilotCharge(price)?.actual_usage || 0;
}

export function getCopilotPrice(price: Price): number {
  return getCopilotCharge(price)?.price || 0;
}

export function getPspPlanBreakdown(priceWithPsp: Price) {
  return priceWithPsp.breakdown.find(
    (model: { plan_id: string }) => String(model.plan_id) === PROACTIVE_SUPPORT_PLUS_BASE_ID,
  );
}

export function getFeaturesData(
  featuresAttributeObj: {
    allFeatures: string[];
    availableFeaturesForApp: string[];
    availableEssentialFeatures: string[];
    availableAdvancedFeatures: string[];
    availableExpertFeatures: string[];
    availablePSPFeatures: string[];
  },
  intl: IntlService,
  showOnlyDifferences = false,
  searchValue = '',
) {
  let data: any = [];
  featuresAttributeObj.allFeatures.forEach((feature) => {
    let featureValue = intl.t('pricing-and-packaging.features.'.concat(feature));
    if (
      searchValue &&
      !featureValue.toLowerCase().includes(searchValue.toLowerCase()) &&
      !feature.toLowerCase().includes(searchValue.toLowerCase())
    ) {
      return;
    }
    data.push({
      feature: featureValue,
      feature_tooltip: intl.t('pricing-and-packaging.features.'.concat(feature).concat('_tooltip')),
      currentPlan: featuresAttributeObj.availableFeaturesForApp.includes(feature),
      essentialPlan: featuresAttributeObj.availableEssentialFeatures.includes(feature),
      advancedPlan:
        featuresAttributeObj.availableEssentialFeatures.includes(feature) ||
        featuresAttributeObj.availableAdvancedFeatures.includes(feature),
      expertPlan:
        featuresAttributeObj.availableEssentialFeatures.includes(feature) ||
        featuresAttributeObj.availableAdvancedFeatures.includes(feature) ||
        featuresAttributeObj.availableExpertFeatures.includes(feature),
      proactivePlan: featuresAttributeObj.availablePSPFeatures.includes(feature),
    });
  });
  data = data.reject(
    (el: any) =>
      !el.currentPlan &&
      !el.essentialPlan &&
      !el.advancedPlan &&
      !el.expertPlan &&
      !el.proactivePlan,
  );

  if (showOnlyDifferences) {
    data = data.reject((feature: any) => {
      return feature.currentPlan && feature.essentialPlan;
    });
  }

  return {
    allData: data,
    featuresNew: data.filter((feature: any) => {
      return (
        !feature.currentPlan &&
        (feature.essentialPlan ||
          feature.advancedPlan ||
          feature.expertPlan ||
          feature.proactivePlan)
      );
    }),
    featuresLost: data.filter((feature: any) => {
      return (
        feature.currentPlan &&
        !feature.essentialPlan &&
        !feature.advancedPlan &&
        !feature.expertPlan &&
        !feature.proactivePlan
      );
    }),
  };
}

export const helpLinkUrlsForStripeMigrations: { [index: string]: string } = {
  seats: 'https://www.intercom.com/help/en/articles/8205716-seats',
  fullSeat: 'https://www.intercom.com/help/en/articles/8205716-seats#h_8aec0ebdae',
  liteSeat: 'https://www.intercom.com/help/en/articles/8205716-seats#h_b37e53dd64',
  psp: 'https://www.intercom.com/help/en/articles/9061648-proactive-support-plus-add-on',
  learnMore: 'https://www.intercom.com/help/en/articles/9061614-intercom-plans-explained',
};

export function formattedMigrationDate(migrationDate: Date, intl: IntlService) {
  return intl.formatDate(moment(migrationDate), {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
}
